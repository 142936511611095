<template>
    <div :class="{ compact, 'container-overflow-forbidden': noOverflow }">
        <div
            v-for="(componentGroup, cGroupIndex) in groupedComponents"
            :key="`c-group-${cGroupIndex}`"
            class="relative"
            :class="{ 'mt-12 lg:mt-20': cGroupIndex > 0 && componentGroup[0].type === 'MenuBarComponent' }"
        >
            <div
                v-for="(component, i) in componentGroup"
                :id="component.data.fragment_name ? `c___${component.data.fragment_name}` : null"
                :ref="`c-${i}`"
                :key="`c-${i}`"
                class="c-wrapper"
                :style="`${
                    component.type === 'MenuBarComponent'
                        ? `top: ${stickyElementTopPosition}; z-index: ${components.length + 1}`
                        : `z-index: ${i + 1}`
                }`"
                :class="`${getCalculatedExtraPaddingBottom(i)} ${
                    component.type === 'MenuBarComponent' ? 'sticky' : 'relative'
                }`"
            >
                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* MenuBarComponent */}}
                {{/* * */}}
                <InternalNavBar
                    v-if="component.type === 'MenuBarComponent'"
                    id="internal-navigation"
                    :items="component.data"
                    :active-hash="activeInternalMenuItemHash"
                    @update-active-hash="updateActiveInternalMenuItemHash"
                />

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* TitleComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'TitleComponent'"
                    data-c-type="TitleComponent"
                    class="wysiwyg-content"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-8 lg:col-start-3': !hasParentContainer }">
                        <component :is="component.data.selectedElement" v-if="component.data.selectedElement">
                            {{ component.data.text }}
                        </component>
                        <h1 v-else class="header-1">
                            {{ component.data.text }}
                        </h1>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* HighlightedComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'HighlightedComponent'"
                    data-c-type="HighlightedComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-8 lg:col-start-3': !hasParentContainer }">
                        <p v-if="component.data.text" class="lead" :style="`text-align: ${component.data.alignment}`">
                            {{ component.data.text }}
                        </p>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* TextComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'TextComponent'"
                    data-c-type="TextComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div
                        class="wysiwyg-content"
                        :class="{ 'col-span-12 lg:col-span-8 lg:col-start-3': !hasParentContainer }"
                        v-html="component.data.text"
                    ></div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* QuoteWithTextComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'QuoteWithTextComponent'"
                    data-c-type="QuoteWithTextComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-8 lg:col-start-3': !hasParentContainer }">
                        <div
                            v-if="component.data.quote"
                            class="wysiwyg-content quote"
                            v-html="component.data.quote"
                        ></div>
                        <div v-if="component.data.text" class="wysiwyg-content" v-html="component.data.text"></div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* EmbeddedGalleryComponent - type 1 */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'EmbeddedGalleryComponent' && component.data.gallery.slides.length"
                    data-c-type="EmbeddedGalleryComponent"
                >
                    <div v-if="!component.data.appearance || component.data.appearance === 'style1'">
                        <XXLGalleryCarousel :images="component.data.gallery.slides" />
                    </div>
                    <MosaiqueGalleryBlock
                        v-else-if="component.data.appearance === 'style2'"
                        :class="{ container: !hasParentContainer }"
                        :items="component.data.gallery.slides"
                    />
                    <Type2Block
                        v-else-if="component.data.appearance === 'style3'"
                        :title="component.data.gallery.title"
                        :description="component.data.gallery.description"
                        :images="component.data.gallery.slides"
                    />
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* EmbeddedVideoComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'EmbeddedVideoComponent'"
                    data-c-type="EmbeddedVideoComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2': !hasParentContainer }">
                        <div class="aspect-w-16 aspect-h-9">
                            <iframe
                                width="100%"
                                height="100%"
                                :src="`https://www.youtube.com/embed/${component.data.video.video_url}`"
                                :title="component.data.video.title"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                        </div>
                        <div class="wysiwyg-content mt-4" v-html="component.data.video.lead_text"></div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* VideoComponent */}}
                {{/* * */}}
                <VideoGalleryBlock
                    v-if="component.type === 'VideoComponent'"
                    data-c-type="VideoComponent"
                    :videos="component.data"
                />

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* CtaButtonComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'CtaButtonComponent'"
                    data-c-type="CtaButtonComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap pt-8': !hasParentContainer }"
                >
                    <div
                        :class="{
                            'col-span-12 lg:col-span-8 lg:col-start-3': !hasParentContainer,
                            'text-left': component.data.alignment === 'left',
                            'text-center': !component.data.alignment || component.data.alignment === 'center',
                            'text-right': component.data.alignment === 'right',
                        }"
                    >
                        <Button
                            :to="component.data.link"
                            :target="component.data.open_in_new_window ? '_blank' : '_self'"
                            :label="component.data.text"
                            class="w-full sm:w-[unset]"
                        />
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* Map2dComponent */}}
                {{/* * */}}
                <div v-if="component.type === 'Map2dComponent'" data-c-type="Map2dComponent" style="padding-top: 0">
                    <div
                        :class="{
                            'z-2 relative pt-24 lg:pt-32 xl:pt-44 -mt-20 sm:mt-[-10vw] lg:mt-[-9vw] xl:mt-[-7.5vw] 2xl:mt-[-6vw] 3xl:mt-[-5.5vw]':
                                i > 0,
                        }"
                    >
                        <Map2DAsEditableContentComponent
                            :should-display-title="i > 0"
                            :title="component.data.map2D.name"
                            :layers="component.data.map2D.layers"
                        />
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* EmbeddedElements */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'EmbeddedElements'"
                    data-c-type="EmbeddedElements"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-8 lg:col-start-3 ': !hasParentContainer }">
                        <div class="embed-content" v-html="component.data.element.embed"></div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* VisitorCategoryComponent "big" template type (= cards in 2 columns) */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'VisitorCategoryComponent' && component.data.template === 'big'"
                    data-c-type="VisitorCategoryComponentBIG"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div class="col-span-12 lg:col-span-10 lg:col-start-2 separator-border-top-line">
                        <h2 class="header-2 xl:max-w-2xl">{{ component.data.category.name }}</h2>
                    </div>
                    <div
                        class="col-span-12 lg:col-span-8 lg:col-start-3 whitespace-pre-line"
                        v-html="component.data.category.description"
                    ></div>
                    <div :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-4 lg:gap-y-12 lg:gap-x-7 mt-8">
                            <LigetCard
                                v-for="item in component.data.category.items"
                                :key="`${component.id}-card-${item.id}`"
                                :img="$getMediaImage(item.media, 'hero', 'sm')"
                                :title="item.name"
                                :to="
                                    localePath({
                                        name: 'visitor-information-visitorInformation',
                                        params: { visitorInformation: item.slug },
                                    })
                                "
                                :description="item.sub_name"
                            />
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* VisitorCategoryComponent "small" template (= Service group block with mini card carousel) */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'VisitorCategoryComponent' && component.data.template === 'small'"
                    data-c-type="VisitorCategoryComponentSM"
                >
                    <ServiceGroupBlock
                        :title="component.data.category.name"
                        :lead="component.data.category.description"
                        :items="component.data.category.items"
                        :active-item-hash="activeInternalMenuItemHash"
                        :carousel-type="2"
                    />
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* DocumentCategoryComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'DocumentCategoryComponent'"
                    data-c-type="DocumentCategoryComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div
                        class="text-left"
                        :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }"
                    >
                        <div v-if="component.data.items.length" class="grid grid-cols-12 base-responsive-gap">
                            <div
                                v-for="document in component.data.items"
                                :key="`${component.id}-document-${document.id}`"
                                class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
                            >
                                <DocumentCard
                                    :size="document.document_size"
                                    :title="document.name"
                                    :url="document.media"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* FacebookGroupComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'FacebookGroupComponent'"
                    data-c-type="FacebookGroupComponent"
                    class="container grid grid-cols-12 base-responsive-gap"
                >
                    <div class="col-span-12 lg:col-span-7">
                        <h2 v-if="component.data.title" class="header-1">{{ component.data.title }}</h2>
                        <div v-if="component.data.text" class="wysiwyg-content mt-4" v-html="component.data.text"></div>
                    </div>
                    <div
                        class="col-span-12 lg:col-span-4 lg:col-start-9 embed-content"
                        v-html="component.data.code"
                    ></div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* NewsletterSubscriptionComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'NewsletterSubscriptionComponent'"
                    data-c-type="NewsletterSubscriptionComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }">
                        <NewsletterHubspotForm card />
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* LigetPlusComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'LigetPlusComponent'"
                    data-c-type="LigetPlusComponent"
                    :class="{ container: !hasParentContainer }"
                >
                    <LigetPlusDetailsBlock
                        :is-narrow="component.data.is_narrow"
                        :title="component.data.card_group.name"
                        :items="component.data.card_group.items"
                        :btn-label="component.data.card_group.buttonText"
                        :btn-url="component.data.card_group.buttonLink"
                        :secondary-btn-label="component.data.card_group.buttonText2"
                        :secondary-btn-url="component.data.card_group.buttonLink2"
                    />
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* SingleImageComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'SingleImageComponent'"
                    data-c-type="SingleImageComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div
                        class="container-overflow-forbidden"
                        :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }"
                    >
                        <img
                            v-if="!component.data.appearance || component.data.appearance === 'image'"
                            class="w-full h-auto base-responsive-rounding"
                            :src="component.data.image"
                            alt=""
                        />
                        <div
                            v-else-if="component.data.appearance === 'image_text'"
                            class="grid grid-cols-1 md:grid-cols-2 base-responsive-gap"
                        >
                            <img class="w-full h-auto base-responsive-rounding" :src="component.data.image" alt="" />
                            <div class="wysiwyg-content" v-html="component.data.text"></div>
                        </div>
                        <div
                            v-else-if="component.data.appearance === 'text_image'"
                            class="grid grid-cols-1 md:grid-cols-2 base-responsive-gap"
                        >
                            <div class="wysiwyg-content order-2 lg:order-1" v-html="component.data.text"></div>
                            <img
                                class="w-full h-auto base-responsive-rounding order-1 lg:order-2"
                                :src="component.data.image"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* HighlightedEventRecommendationComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'HighlightedEventRecommendationComponent'"
                    data-c-type="HighlightedEventRecommendationComponent"
                >
                    <div :class="{ container: !hasParentContainer }">
                        <FeaturedProgramCard
                            jumbo
                            :title="component.data.main_event.name"
                            :description="component.data.main_event.lead_text"
                            :date="$getFormattedEventInterval(component.data.main_event)"
                            :event-types="component.data.main_event.categories_id"
                            :img="$getMediaImage(component.data.main_event.media, 'lead')"
                            :location="$getEventLocationName(component.data.main_event.location_id)"
                            :url="$getEventUrl(component.data.main_event)"
                            :ticket-url="component.data.main_event.ticket_url"
                            :registration-url="component.data.main_event.registration_url"
                            :has-series="!!component.data.main_event.series"
                        />
                    </div>

                    <CardCarousel
                        v-if="component.data.other_events.length"
                        type="program"
                        :items="component.data.other_events"
                        class="mt-16"
                    />
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* EventsComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'EventsComponent' && component.data.events.length"
                    data-c-type="EventsComponent"
                    class="bg-gray-200 pb-12 lg:pb-24"
                    :class="{
                        'mt-12 sm:mt-16 lg:mt-24': i > 0 && !internalMenuComponent && !compact,
                        'mt-12 lg:mt-16': i > 0 && !internalMenuComponent && compact,
                    }"
                >
                    <div :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }">
                        <div
                            class="grid grid-cols-1 card-grid-responsive-gap"
                            :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2  ': !hasParentContainer }"
                        >
                            <h2 v-if="component.data.title" class="header-1 text-center mb-8 lg:mb-12">
                                {{ component.data.title }}
                            </h2>
                            <div
                                v-for="eventData in component.data.events.slice(0, 3)"
                                :key="`${component.id}-program-${eventData.id}`"
                            >
                                <ProgramCard
                                    :title="eventData.name"
                                    :date="$getFormattedEventInterval(eventData)"
                                    :description="eventData.lead_text"
                                    :event-types="eventData.categories_id"
                                    :img="$getMediaImage(eventData.media, 'lead')"
                                    :location="$getEventLocationName(eventData.location_id)"
                                    :url="$getEventUrl(eventData)"
                                    :ticket-url="eventData.ticket_url"
                                    :registration-url="eventData.registration_url"
                                    :has-series="!!eventData.series"
                                />
                            </div>
                            <div
                                v-if="component.data.button_label && component.data.button_url"
                                class="text-center lg:mt-4"
                            >
                                <Button
                                    :to="component.data.button_url"
                                    :label="component.data.button_label"
                                    target="_blank"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* SingleEventComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'SingleEventComponent'"
                    data-c-type="SingleEventComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }">
                        <ProgramCard
                            featured
                            :title="component.data.name"
                            :date="$getFormattedEventInterval(component.data)"
                            :description="component.data.lead_text"
                            :event-types="component.data.categories_id"
                            :img="$getMediaImage(component.data.media, 'lead')"
                            :location="$getEventLocationName(component.data.location_id)"
                            :url="$getEventUrl(component.data)"
                            :ticket-url="component.data.ticket_url"
                            :registration-url="component.data.registration_url"
                            :has-series="!!component.data.series"
                        />
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* SeriesComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'SeriesComponent'"
                    data-c-type="SeriesComponent"
                    :class="{ 'container grid grid-cols-12 base-responsive-gap': !hasParentContainer }"
                >
                    <div :class="{ 'col-span-12 lg:col-span-10 lg:col-start-2 ': !hasParentContainer }">
                        <!--                            :img="$getMediaImage(component.data.media, 'lead')"-->

                        <ProgramSeriesCard
                            featured
                            :title="component.data.name"
                            :lead="component.data.sub_title"
                            :description="component.data.lead_text"
                            :event-types="component.data.categories_id"
                            :img="require('~/assets/images/balloon-fly/active.jpg')"
                            :url="
                                localePath({
                                    name: 'program-series-programSeries',
                                    params: { programSeries: component.data.slug },
                                })
                            "
                        />
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* CarouselComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'CarouselComponent'"
                    data-c-type="CarouselComponent"
                    :class="{ container: !hasParentContainer }"
                >
                    <PromoCarousel :items="component.data.items || component.data" />
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* AwardsComponent */}}
                {{/* * */}}
                <div
                    v-if="component.type === 'AwardsComponent'"
                    data-c-type="AwardsComponent"
                    :class="{ container: !hasParentContainer }"
                >
                    <AwardsBlock
                        :title="component.data.title"
                        :description="component.data.description"
                        :media="component.data.media"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import announcement from '@/mixins/announcement';
import AwardsBlock from '@/components/Blocks/AwardsBlock/AwardsBlock.vue';
import XXLGalleryCarousel from '@/components/Carousels/XXLGalleryCarousel/XXLGalleryCarousel.vue';
import DocumentCard from '@/components/Cards/DocumentCard/DocumentCard.vue';
import NewsletterHubspotForm from '@/components/Forms/NewsletterHubspotForm.vue';
import ProgramCard from '@/components/Cards/ProgramCard/ProgramCard.vue';
import ProgramSeriesCard from '@/components/Cards/ProgramSeriesCard/ProgramSeriesCard.vue';
import LigetPlusDetailsBlock from '@/components/Blocks/LigetPlusDetailsBlock/LigetPlusDetailsBlock.vue';
import Map2D from '@/components/Map/Map2D.vue';
import Type8Block from '@/components/Blocks/Type8Block/Type8Block.vue';
import LigetCard from '@/components/Cards/LigetCard/LigetCard.vue';
import ServiceGroupBlock from '@/components/Blocks/ServiceGroupBlock/ServiceGroupBlock.vue';
import MosaiqueGalleryBlock from '@/components/Blocks/MosaiqueGalleryBlock/MosaiqueGalleryBlock.vue';
import Type2Block from '@/components/Blocks/Type2Block/Type2Block.vue';
import InternalNavBar from '@/components/Navigation/InternalNavBar/InternalNavBar.vue';
import FeaturedProgramCard from '@/components/Cards/FeaturedProgramCard/FeaturedProgramCard.vue';
import CardCarousel from '@/components/Carousels/CardCarousel/CardCarousel.vue';
import PromoCarousel from '@/components/Carousels/PromoCarousel/PromoCarousel.vue';
import VideoGalleryBlock from '@/components/Blocks/VideoGalleryBlock/VideoGalleryBlock.vue';
import Map2DAsEditableContentComponent from '@/components/Map/Map2DAsEditableContentComponent.vue';

export default {
    name: 'EditableContentComponentGroup',
    components: {
        AwardsBlock,
        VideoGalleryBlock,
        PromoCarousel,
        CardCarousel,
        FeaturedProgramCard,
        InternalNavBar,
        Type2Block,
        MosaiqueGalleryBlock,
        ServiceGroupBlock,
        LigetCard,
        Type8Block,
        Map2D,
        NewsletterHubspotForm,
        DocumentCard,
        XXLGalleryCarousel,
        ProgramCard,
        ProgramSeriesCard,
        LigetPlusDetailsBlock,
        Map2DAsEditableContentComponent,
    },
    mixins: [announcement],
    props: {
        components: {
            type: Array,
            required: true,
        },
        compact: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasParentContainer: {
            type: Boolean,
            required: false,
            default: false,
        },
        noOverflow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            activeInternalMenuItemHash: '',
        };
    },
    computed: {
        internalMenuComponent() {
            return this.components.find((c) => c.type === 'MenuBarComponent');
        },
        allComponentsExceptInternalMenu() {
            return this.components.filter((c) => c.type !== 'MenuBarComponent');
        },
        groupedComponents() {
            // Every time a component with type 'MenuBarComponent' is in the list, we create a new group, in order
            // to create a new .relative wrapper parent. This is needed since the menu bar component is a sticky element,
            // and it only needs to be sticky in its "own group" of elements.
            return this.components.reduce((acc, curr) => {
                if (!acc.length || curr.type === 'MenuBarComponent') {
                    acc.push([]);
                }
                acc[acc.length - 1].push(curr);
                return acc;
            }, []);
        },
    },
    watch: {
        '$route.hash'() {
            this.scrollToComponent(this.$route.hash);
        },
    },
    mounted() {
        if (this.$route.hash) {
            this.activeInternalMenuItemHash = this.$route.hash;
        } else if (this.internalMenuComponent) {
            this.activeInternalMenuItemHash = this.internalMenuComponent.data[0].url;
        }

        if (this.$route.hash) {
            this.scrollToComponent(this.$route.hash, 300);
        }
    },
    methods: {
        scrollToComponent(hash, timeout = 0) {
            const hashName = hash.replace('#', '');

            const componentWithHashID = document.querySelector(`#c___${hashName}`);
            const componentInComponentWithHashID = document.querySelector(`#cc___${hashName}`);

            let offset = 0;

            if (window.innerWidth < 640) {
                offset += 30;
            }

            if (this.internalMenuComponent) {
                offset += 40;
            }

            if (!document.querySelector(hash)) {
                if (componentWithHashID) {
                    setTimeout(() => {
                        this.$scrollToElement(`#c___${hashName}`, offset);
                    }, timeout);
                } else if (componentInComponentWithHashID) {
                    setTimeout(() => {
                        this.$scrollToElement(`#cc___${hashName}`, offset + 100);
                    }, timeout);
                }
            }
        },
        getCalculatedExtraPaddingBottom(i) {
            if (
                this.allComponentsExceptInternalMenu[i + 1] &&
                this.allComponentsExceptInternalMenu[i + 1].type === 'Map2dComponent'
            ) {
                return 'extra-padding-bottom';
            }

            return '';
        },
        updateActiveInternalMenuItemHash(hash) {
            this.activeInternalMenuItemHash = hash;
            this.scrollToComponent(hash);
        },
    },
};
</script>

<style>
.c-wrapper:not(:first-child) > * {
    @apply pt-12 sm:pt-16 lg:pt-24;
    &[data-c-type='CtaButtonComponent'] {
        @apply pt-6 sm:pt-8 lg:pt-12;
    }
}

.compact .c-wrapper:not(:first-child) > * {
    @apply pt-12 lg:pt-16;
    &[data-c-type='CtaButtonComponent'] {
        @apply pt-6 lg:pt-8;
    }
}

.TitleComponent {
    @apply -mb-4 lg:-mb-8;
}

.embed-content iframe {
    width: 100% !important;
    max-width: 100%;
}
.extra-padding-bottom > * {
    @apply pb-24 lg:pb-32 xl:pb-44;
}
</style>
